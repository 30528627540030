import React, {useEffect, useState} from "react";
import NavigationBar from "./components/NavigationBar";
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import {useTranslation} from "react-i18next";
import {useAuth} from "./AuthWrapper";
import {WordsSummary} from "./data/words";
import {getWordsSummary} from "./repositories/wordRepository";

function Student() {
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth();
    const [allWords, setAllWords] = useState<WordsSummary | undefined>(undefined);

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: "https://wordleaf-tako.herokuapp.com",
                    scope: "read:current_user",
                });

                getWordsSummary(accessToken).then((summary) => {
                    setAllWords(summary)
                })
            } catch (error) {
                console.log(error)
            }
        }

        getAccessToken().then(() => {
            console.log('got access token from auth0')
        })
    }, [getAccessTokenSilently]);
    return (
        <>
            <NavigationBar/>
            <MainSection>
                <Sidebar/>
                <ContentContainer>
                    <h2>{t('student.homeTitle')}</h2>
                    <ul>
                        {allWords?.words.map(word => {
                            return (
                                <li key={word.uuid}>
                                    {word.lookupDictionaryId} + {word.lookupId}
                                </li>
                            )
                        })}
                    </ul>
                </ContentContainer>
            </MainSection>
        </>
    );
}

const MainSection = styled.section`
    flex: 1;
    display: flex;
    background-color: ${(props) => props.theme.background};
    padding-left: 1rem;
`

const ContentContainer = styled.section`
    padding: 1rem;
    h2 {
        margin: 0;
    }
`

export default Student;
