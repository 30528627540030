import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${(props) => props.theme.background };
        color: ${(props) => props.theme.foreground };
        transition: all 0.24s linear;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: ${(props) => props.theme.secondary };
        font-weight: 700;

        :hover {
            color: ${(props) => props.theme.secondaryHighlight };
        }
    }

    p {
        color: ${(props) => props.theme.foregroundAccent };
    }

    button {
        border-radius: 10px;
        background-color: ${(props) => props.theme.primary };
        padding: 0.5rem;
        font-weight: 700;
        border: none;
    }
`

export default GlobalStyle