import React from "react";
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse, LogoutOptions} from "@auth0/auth0-spa-js";
import {RedirectLoginOptions} from "@auth0/auth0-react/src/auth0-context";

interface AuthProviderProps {
    children: React.ReactNode
}

export function AuthProvider(props: AuthProviderProps) {
    return (
        <Auth0Provider
            domain="wordleaf-tako.jp.auth0.com"
            clientId="PzuW9sGRAkFXKI6qKUBmtBzGkEPvBmgq"
            redirectUri={window.location.origin + "/student"}
            audience="https://wordleaf-tako.herokuapp.com"
            scope="read:current_user"
        >
            {props.children}
        </Auth0Provider>
    );
}

export class CTAuthContext {
    constructor(
        isAuthenticated: boolean,
        isLoading: boolean,
        user: CTUser,
        loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>,
        logout: (options?: LogoutOptions) => void = () => {
        },
        getAccessTokenSilently: {
            (
                options: GetTokenSilentlyOptions & { detailedResponse: true }
            ): Promise<GetTokenSilentlyVerboseResponse>;
            (options?: GetTokenSilentlyOptions): Promise<string>;
            (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
        },
        error?: Error,
    ) {
        this.isAuthenticated = isAuthenticated
        this.isLoading = isLoading
        this.user = user
        this.loginWithRedirect = loginWithRedirect
        this.logout = logout
        this.getAccessTokenSilently = getAccessTokenSilently
        this.error = error
    }

    error?: Error;
    isAuthenticated: boolean;
    isLoading: boolean;
    user?: CTUser;
    loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
    getAccessTokenSilently: {
        (
            options: GetTokenSilentlyOptions & { detailedResponse: true }
        ): Promise<GetTokenSilentlyVerboseResponse>;
        (options?: GetTokenSilentlyOptions): Promise<string>;
        (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
    };
    logout: (options?: LogoutOptions) => void = () => {
    };
}

export class CTUser {
    name?: string;
    picture?: string;
    sub?: string;
}

export const useAuth = (): CTAuthContext => {
    const {error, user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently} = useAuth0();
    if (!loginWithRedirect || !logout) {
        throw Error('Auth0 login components are not loading');
    }
    const wrappedUser = new CTUser();
    wrappedUser.name = user?.name
    wrappedUser.picture = user?.picture
    wrappedUser.sub = user?.sub

    return new CTAuthContext(
        isAuthenticated,
        isLoading,
        wrappedUser,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
        error
    );
}

