import React from 'react';
import styled from 'styled-components';
import addWordsImage from '../../images/add_words.png';
import ocr from '../../images/ocr.png';
import cardFront from '../../images/card_front.png';
import cardBack from '../../images/card_back.png';
import myWords from '../../images/my_words.png';
import tags from '../../images/tags.png';
import quickSearch from '../../images/quick_search.png';
import wordDetails from '../../images/word_details.png';
import {useTranslation} from 'react-i18next';

function PromotionalSection() {
    const {t} = useTranslation();

    return (
        <>
            <FeatureDescriptionContainer>
                <div>
                    <ScreenshotContainer>
                        <Screenshot
                            src={ocr}
                            alt={t('homepage.accesability.ocr')}/>
                        <Screenshot
                            src={addWordsImage}
                            alt={t('homepage.accesability.addWords')}/>
                    </ScreenshotContainer>
                    <FeatureDescription>
                        <h2>{t('homepage.grabAnySentence')}</h2>
                        <p>
                            {t('homepage.whenYouFindWord')}
                        </p>
                    </FeatureDescription>
                </div>
            </FeatureDescriptionContainer>

            <FeatureDescriptionContainer>
                <div>
                    <ScreenshotContainer>
                        <Screenshot
                            src={cardFront}
                            alt={t('homepage.accesability.flashcardFront')}/>
                        <Screenshot
                            src={cardBack}
                            alt={t('homepage.accesability.flashcardBack')}/>
                    </ScreenshotContainer>
                    <FeatureDescription>
                        <h2>{t('homepage.buildDailyHabit')}</h2>
                        <p>
                            {t('homepage.weScheduleFlashcards')}
                        </p>
                    </FeatureDescription>
                </div>
            </FeatureDescriptionContainer>

            <FeatureDescriptionContainer>
                <div>
                    <ScreenshotContainer>
                        <Screenshot
                            src={myWords}
                            alt={t('homepage.accesability.myWords')}/>
                        <Screenshot
                            src={tags}
                            alt={t('homepage.accesability.tags')}/>
                    </ScreenshotContainer>
                    <FeatureDescription>
                        <h2>{t('homepage.keepTrack')}</h2>
                        <p>
                            {t('homepage.organizeEverything')}
                        </p>
                    </FeatureDescription>
                </div>
            </FeatureDescriptionContainer>

            <FeatureDescriptionContainer>
                <div>
                    <ScreenshotContainer>
                        <Screenshot
                            src={quickSearch}
                            alt={t('homepage.accesability.quickSearch')}/>
                        <Screenshot
                            src={wordDetails}
                            alt={t('homepage.accesability.wordDetails')}/>
                    </ScreenshotContainer>
                    <FeatureDescription>
                        <h2>{t('homepage.quicklyCheckWord')}</h2>
                        <p>
                            {t('homepage.contextIncludesDictionary')}
                        </p>
                    </FeatureDescription>
                </div>
            </FeatureDescriptionContainer>
        </>
    )
}


const ScreenshotContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
`

const Screenshot = styled.img`
  max-height: 24rem;
  margin-right: 4rem;

  @media (max-width: 768px) {
    max-height: 88vw;
    margin-right: 3vw;

    :nth-child(1) {
        margin-left: 3vw;
    }
  }
`

const FeatureDescriptionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10rem 0 10rem 0;
  align-items: center;

  :nth-child(odd) {
    background-color: ${(props) => props.theme.accent};

    > div {
        flex-direction: row-reverse;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }
  }

  :last-child {
    margin-bottom: 10rem;
  }

  > div {
      display: flex;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
  }

  @media (max-width: 768px) {
      margin: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;

      > div {
        margin-top: 2rem;
        margin-bottom: 2rem;
        flex-direction: column-reverse;
      }
  }
`

const FeatureDescription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;

  h2 {
    font-size: 1.75rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom : 2rem;
  }
`

export default PromotionalSection;