import {DefaultTheme} from 'styled-components';

export const lightTheme: DefaultTheme = {
    primary: "#1FCCB5",
    secondary: "#4A90E2",
    secondaryHighlight: "#89BFFF",
    foreground: "#000000",
    foregroundAccent: "#444444",
    background: "#ffffff",
    accent: "#E4EDF3"
};

export const darkTheme: DefaultTheme = {
    primary: "#1FCCB5",
    secondary: "#89BFFF",
    secondaryHighlight: "#4A90E2",
    foreground: "#ffffff",
    foregroundAccent: "#C2D1DB",
    background: "#000000",
    accent: "#2E363C"
};