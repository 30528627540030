import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <FooterContainer>
            <a href="/privacypolicy">{t('homepage.privacyPolicy')}</a>
            <a href="/termsofservice">{t('homepage.termsOfService')}</a>
            <p>Canyon Boak 2021</p>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    text-align: center;
    height: 6rem;
    padding: 1rem;

    a {
        margin-right: 1rem;
        text-decoration: none;
    }
`

export default Footer;