import React from "react";
import styled from "styled-components";

function Sidebar() {
    return (
        <SidebarContainer>
        </SidebarContainer>
    )
}

const SidebarContainer = styled.aside`
  width: 160px;
  border-right: 1px solid ${(props) => props.theme.accent};
`

export default Sidebar;