import React from 'react';
import styled, {DefaultTheme, withTheme} from 'styled-components';
import icon from '../../images/context_icon.png';
import appStore from '../../images/app_store_badge.png';
import phone from '../../images/add_words.png';
import {ReactComponent as BackgroundImage} from '../../images/backgroundShape.svg';
import {useTranslation} from 'react-i18next';

interface TopContentSectionProps {
    theme: DefaultTheme
}

function TopContentSection(props: TopContentSectionProps) {
    const {t} = useTranslation();
    return (
        <TopContent>
            <TopContentContainer>
                <AppIcon
                src={icon}
                alt={t('homepage.accesability.icon')}/>
                <DescriptionContainer>
                    <h1>Context</h1>
                    <p>{t('homepage.tagline')}</p>
                    <AppStoreLink
                        href="https://apps.apple.com/us/app/tako-words/id966420453"
                        aria-label={t('homepage.accesability.download')}>
                        <img
                            src={appStore}
                            alt={t('homepage.accesability.download')}/>
                    </AppStoreLink>
                </DescriptionContainer>
                <VideoContainer>
                    <img
                        src={phone}
                        alt={t('homepage.accesability.video')}
                    />
                </VideoContainer>
            </TopContentContainer>
            <BackgroundImageContainer>
                <BackgroundImage fill={props.theme.accent} stroke={props.theme.accent} preserveAspectRatio="none"/>
            </BackgroundImageContainer>
        </TopContent>
    )
}

const AppIcon = styled.img`
  max-height: 10rem;
  box-shadow: 0 3.82178px 21.0198px rgba(0, 0, 0, 0.05);
  border-radius: 34px;
`

const AppStoreLink = styled.a`
    height: 48px;
    img {
        height: 100%;
    }
`

const BackgroundImageContainer = styled.div`
    position: absolute;
    top: -5rem;
    left: 0;
    right: 0;
    z-index: -1;

     svg {
         width: 100%;
         height: 40rem;
     }

     @media (max-width: 768px) {
        top: 0rem;

        svg {
            height: 13rem;
        }
     }
`

const TopContent = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 8rem;
`

const TopContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        margin-bottom: 2rem;
    }
`

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    max-width: 24rem;
    margin-right: 8rem;

    @media (max-width: 768px) {
        margin-right: 2rem;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
`

const VideoContainer = styled.div`
    height: 32rem;

    img {
        height: 100%;
    }
`

export default withTheme(TopContentSection);