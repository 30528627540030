import {Link} from 'react-router-dom';
import styled from 'styled-components';
import logoDark from '../images/logo_dark.svg';
import logoLight from '../images/logo_light.svg';
import {lightTheme} from './theme/Theme';
import {useAuth} from "../AuthWrapper";

function NavigationBar() {
    const {user, isAuthenticated} = useAuth();

    if (isAuthenticated) {
        return (
            <NavigationBarContainer>
                <LeftBarItems>
                    <Link to="/student">
                        <Logo/>
                    </Link>
                </LeftBarItems>

                <RightBarItems>
                    <LogoutButton/>
                    <ProfileImage src={user?.picture} alt={user?.name}/>
                </RightBarItems>
            </NavigationBarContainer>
        );
    } else {
        return (
            <NavigationBarContainer>
                <LeftBarItems>
                    <Link to="/student">
                        <Logo/>
                    </Link>
                </LeftBarItems>
                <RightBarItems>
                    <LoginButton/>
                </RightBarItems>
            </NavigationBarContainer>
        );
    }
}

const LoginButton = () => {
    const {loginWithRedirect} = useAuth();

    return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

const LogoutButton = () => {
    const {logout} = useAuth();

    return (
        <button onClick={() => logout({returnTo: window.location.origin + "/student"})}>
            Log Out
        </button>
    );
};

const LeftBarItems = styled.div`
    display: flex;
    padding-left: 1rem;
`

const RightBarItems = styled.div`
    display: flex;
    align-items: center;
    padding-right: 1rem;
`

const Logo = styled.div`
    width: 40px;
    height: 40px;
    background-image: url(${(props) => props.theme === lightTheme ? logoLight : logoDark});
    background-size: 40px;
`

const ProfileImage = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 1rem;
`

const NavigationBarContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.accent};

    a {
        margin-top: 0.25rem;
    }

    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.accent};
    }

    button {
        background-color: ${(props) => props.theme.background};
        color: ${(props) => props.theme.foregroundAccent};
        border-radius: 3rem;
        padding: 0.25rem;
        width: 6rem;
        height: 2rem;
        transition: all 0.24s linear;
        cursor: pointer;

        :hover {
            color: ${(props) => props.theme.background};
            background-color: ${(props) => props.theme.secondary};
        }
    }
`

export default NavigationBar;