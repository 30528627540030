import React, {Suspense, useEffect, useState} from 'react';
import TopContentSection from './components/homepage/TopContentSection';
import Header from './components/homepage/Header';
import PromotionalSection from './components/homepage/PromotionalSection';
import Footer from './components/homepage/Footer';
import styled, {ThemeProvider} from 'styled-components';
import GlobalStyle from './components/theme/Global';
import {darkTheme, lightTheme} from './components/theme/Theme';
import {LIGHT_THEME, windowColorScheme} from './components/theme/WindowWrapper';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Student from './Student';

function App() {
    const [theme, setTheme] = useState(LIGHT_THEME);
    // const toggleTheme = () => {
    //     if (theme === LIGHT_THEME) {
    //         setTheme(DARK_THEME);
    //     } else {
    //         setTheme(LIGHT_THEME);
    //     }
    // }

    useEffect(() => {
        setTheme(windowColorScheme())
    }, [])

    return (
        <Suspense fallback={<Loader/>}>
            <ThemeProvider theme={theme === LIGHT_THEME ? lightTheme : darkTheme}>
                <main className="App">
                    <GlobalStyle/>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={Homepage()}/>
                            <Route path="/student" element={Student()}/>
                        </Routes>
                    </BrowserRouter>
                </main>
            </ThemeProvider>
        </Suspense>
    );
}

const Homepage = () => (
    <>
        {/* <button onClick={toggleTheme}>Toggle Theme for Testing</button> */}
        <Header/>
        <div>
            <TopContentSection/>
            <PromotionalSection/>
        </div>
        <Footer/>
    </>
)

const Loader = () => (
    <div className="App">
      <LoadingContainer>loading...</LoadingContainer>
    </div>
);

const LoadingContainer = styled.div`
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

export default App;
