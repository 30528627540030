import {WordsSummary} from "../data/words";

export const getWordsSummary = async (accessToken: string): Promise<WordsSummary | undefined> => {
    try {
        const getWordsUrl = "/api/words/";
        const wordsResponse = await fetch(getWordsUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })

        return await wordsResponse.json()
    } catch (error) {
        console.log(error)
    }

    return undefined
}