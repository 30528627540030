import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

function Header() {
    const { t, i18n } = useTranslation();

    const toggleLanguage = () => {
        if (i18n.language === "ja") {
            i18n.changeLanguage("en")
        } else {
            i18n.changeLanguage("ja")
        }
    }

    return (
        <HomepageHeader>
            <button type="button" onClick={() => toggleLanguage()}>{t('homepage.languageToggle')}</button>
            <a href="mailto: studywithcontext@gmail.com">{t('homepage.contactUs')}</a>
        </HomepageHeader>
    )
}

const HomepageHeader = styled.header`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    padding-top: 2rem;

    a, button {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    a {
        margin-top: 0.25rem;
    }

    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.accent };
    }

    button {
        background-color: ${(props) => props.theme.background };
        color: ${(props) => props.theme.foregroundAccent };
        border-radius: 3rem;
        padding: 0.25rem;
        width: 6rem;
        height: 2rem;
        transition: all 0.24s linear;
        cursor: pointer;

        :hover {
            color: ${(props) => props.theme.background };
            background-color: ${(props) => props.theme.secondary};
        }
    }
`

export default Header;